//* Style
import PopupContactUsStyle from './style';

//* Components
import ContactUsSectionPart from '@/components/sections/ContactUsSection';

const PopupContactUs = () => {
	return (
		<PopupContactUsStyle>
			<ContactUsSectionPart isPopup />
		</PopupContactUsStyle>
	);
};

export default PopupContactUs;
