'use client';

import { useRef, useState } from 'react';
import { Radio, Checkbox } from 'antd';

//* Config
import config from '@/helpers/_config';

//* HOC's
import { withDataContext, withLanguageContext } from '@/context';

//* Style
import './custom.radio.css';
import './custom.checkbox.css';
import ContactUsSectionStyle from './style';

//* Components
import Text from '@/components/global/Text';
import Button from '@/components/global/Button';
import Container from '@/components/global/Container';
import CustomLink from '@/components/global/CustomLink';
import FormItem from '@/components/global/Form/FormItem';
import FormInput from '@/components/global/Form/FormInput';
import ScrollActive from '@/components/global/ScrollActive';
import FormSelect from '@/components/global/Form/FormSelect';
import FormContainer from '@/components/global/Form/FormContainer';

const ContactUsSectionPart = ({ contactUs, isPopup = false, translate }) => {
	//! States
	const [isSuccess, setIsSuccess] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	//! Ref
	const titleRef = useRef();

	//! Handle Finish
	const onFinish = (values) => {
		setIsLoading(true);

		if (!isSuccess) {
			return contactUs({ ...values })
				.then(() => {
					setIsSuccess(true);

					setTimeout(() => {
						setIsSuccess(false);
					}, 3000);
				})
				.finally(() => {
					setIsLoading(false); // Finish loading
				});
		}
	};

	return (
		<ContactUsSectionStyle>
			<Container
				row
				full
				className={'justify-center'}>
				<ScrollActive
					lineAnim={!isPopup}
					duration={1.4}
					refsContainer={[titleRef]}>
					<div className={`col-12 text-center overflow-hidden`}>
						<Text
							tag={`h1`}
							ref={titleRef}
							className={`white font-zen-dots-regular ${!isPopup ? 'translate-y-100' : ''}`}
							text={'contactTitle'}
						/>
					</div>
				</ScrollActive>

				<div className={`col-12`}>
					<FormContainer
						onFinish={onFinish}
						className='contact-form-container pT96'>
						<Container
							row
							full>
							<div className='col-6 col-m-12'>
								<FormItem name={'first_name'}>
									<FormInput
										uppercase
										placeholder={translate('firstNamePlaceholder')}
									/>
								</FormItem>
							</div>

							<div className='col-6 col-m-12'>
								<FormItem
									name={'last_name'}
									className={'last-name'}>
									<FormInput
										uppercase
										placeholder={translate('lastNamePlaceholder')}
									/>
								</FormItem>
							</div>

							<div className='col-6 col-m-12 large-mT '>
								<FormItem name={'organization'}>
									<FormInput placeholder={translate('organizationPlaceholder')} />
								</FormItem>
							</div>

							<div className='col-6 col-m-12 large-mT '>
								<FormItem name={'job_title'}>
									<FormInput placeholder={translate('jobTitlePlaceholder')} />
								</FormItem>
							</div>

							<div className='col-12 large-mT '>
								<FormItem
									name={'hiring_authority'}
									label={translate('radioPlaceholder')}
									className={'white h3 font-golos-text-regular radioLabel'}>
									<Radio.Group
										role='radiogroup'
										aria-labelledby='radioGroupLabel'>
										<Radio
											role='radio'
											value={'yes'}>
											{translate('radioYes')}
										</Radio>

										<Radio
											role='radio'
											value={'no'}>
											{translate('radioNo')}
										</Radio>
									</Radio.Group>
								</FormItem>
							</div>

							<div className='col-12 large-mT '>
								<FormItem name={'contacted'}>
									<FormSelect
										role='combobox'
										value='select'
										aria-expanded='false'
										className={'custom-select'}
										aria-label='How do you want to be contacted?*'
										placeholder={translate('selectedPlaceholder')}
										list={[
											{
												id: 'phone',
												name: 'Phone',
											},
											{
												id: 'email',
												name: 'Email',
											},
										]}
									/>
								</FormItem>
							</div>

							<div className='col-6 col-m-12 large-mT '>
								<FormItem
									validationType={'email'}
									name={'email'}>
									<FormInput placeholder={translate('emailPlaceholder')} />
								</FormItem>
							</div>

							<div className='col-6 col-m-12 large-mT '>
								<FormItem name={'phone'}>
									<FormInput
										isPhoneInput
										placeholder={translate('phonePlaceholder')}
									/>
								</FormItem>
							</div>

							<div className='col-12 large-mT '>
								<FormItem name={'message'}>
									<FormInput
										uppercase
										isTextAreaInput
										placeholder={translate('messagePlaceholder')}
									/>
								</FormItem>
							</div>

							<div className='col-12 large-mT '>
								<FormItem name={'checkbox'}>
									<Checkbox.Group role={'radiogroup'}>
										<Checkbox role='checkbox'>
											<span className='opacity07'>{translate('checkboxPlaceholder')}</span>{' '}
											<CustomLink
												external
												className={'link opacity07'}
												content={translate('linkCheckbox')}
												url={config.routes.privacyPolicy.path}
											/>
										</Checkbox>
									</Checkbox.Group>
								</FormItem>
							</div>

							{!isSuccess ? (
								<div className='col-12 large-mT'>
									<Button
										type={'submit'}
										text={translate('submit')}
										className={'primary'}
										disabled={isLoading}
										isLoading={isLoading}
									/>
								</div>
							) : (
								<div className='col-12 large-mT'>
									<p className={`h3 success-color font-golos-text-regular`}>{translate('successText')}</p>
								</div>
							)}
						</Container>
					</FormContainer>
				</div>
			</Container>
		</ContactUsSectionStyle>
	);
};

export default withLanguageContext(withDataContext(ContactUsSectionPart, ['contactUs']), ['translate']);
