import { forwardRef } from 'react';
import { Select } from 'antd';

//* HOC's
import withLanguageContext from '@/context/consumerHOC/LanguageConsumer';

//* Components
import Icon from '../../Icon';

const { Option } = Select;

//* Style
import FormSelectStyle from './style';

const FormSelect = forwardRef(({ listItemHeight, listHeight, getPopupContainer, selectedValue, onChange, placeholder, translate, list = [], objectKey = 'name', objectValue = 'id', value, className, ...props }, ref) => {
	return (
		<FormSelectStyle>
			<Select
				ref={ref}
				listHeight={listHeight}
				listItemHeight={listItemHeight}
				getPopupContainer={getPopupContainer}
				className={className || 'custom-select'}
				value={selectedValue || value || undefined}
				placeholder={placeholder && translate(placeholder)}
				suffixIcon={
					<Icon
						className={'select-arrow'}
						name={'bottom-arrow'}
					/>
				}
				onChange={(val) =>
					onChange &&
					onChange(
						val,
						list.find((i) => i[value] === val)
					)
				}
				{...props}>
				{list?.map((item) => (
					<Option
						key={item[objectValue]}
						value={item[objectValue]}>
						{item[objectKey]}
					</Option>
				))}
			</Select>
		</FormSelectStyle>
	);
});

FormSelect.defaultProps = {
	getPopupContainer: (triggerNode) => triggerNode.parentElement,
};

export default withLanguageContext(FormSelect, ['translate']);
