import styled from 'styled-components';

const FormSelectStyle = styled.div`
	--antSelectDropdownBorderRadius: var(--sp2-5x);
	--antSelectDropdownPad: var(--sp4x);
	/* --antSelectDropdownMTop: 45px; */
	--inputPadTB: var(--sp3x);
	--inputPadLR: var(--sp3x);
	--antSelectDropdownMTop: 57px;

	[class^='ant-']::-ms-clear,
	[class*='ant-']::-ms-clear,
	[class^='ant-'] input::-ms-clear,
	[class*='ant-'] input::-ms-clear,
	[class^='ant-'] input::-ms-reveal,
	[class*='ant-'] input::-ms-reveal {
		display: none;
	}
	input::-ms-clear,
	input::-ms-reveal {
		display: none;
	}
	@-ms-viewport {
		width: device-width;
	}
	[tabindex='-1']:focus {
		outline: none !important;
	}
	hr {
		box-sizing: content-box;
		height: 0;
		overflow: visible;
	}
	abbr[title],
	abbr[data-original-title] {
		text-decoration: underline;
		-webkit-text-decoration: underline dotted;
		text-decoration: underline dotted;
		border-bottom: 0;
		cursor: help;
	}
	address {
		margin-bottom: 1em;
		font-style: normal;
		line-height: inherit;
	}
	input[type='text'],
	input[type='password'],
	input[type='number'],
	textarea {
		-webkit-appearance: none;
	}
	blockquote {
		margin: 0 0 1em;
	}
	dfn {
		font-style: italic;
	}
	b,
	strong {
		font-weight: bolder;
	}
	small {
		font-size: 80%;
	}
	sub,
	sup {
		position: relative;
		font-size: 75%;
		line-height: 0;
		vertical-align: baseline;
	}
	sub {
		bottom: -0.25em;
	}
	sup {
		top: -0.5em;
	}

	pre {
		margin-top: 0;
		margin-bottom: 1em;
		overflow: auto;
	}
	figure {
		margin: 0 0 1em;
	}
	img {
		vertical-align: middle;
		border-style: none;
	}
	a,
	area,
	button,
	[role='button'],
	input:not([type='range']),
	label,
	select,
	summary,
	textarea {
		touch-action: manipulation;
	}
	table {
		border-collapse: collapse;
	}
	caption {
		padding-top: 0.75em;
		padding-bottom: 0.3em;
		color: rgba(0, 0, 0, 0.45);
		text-align: right;
		caption-side: bottom;
	}
	input,
	button,
	select,
	optgroup,
	textarea {
		margin: 0;
		color: inherit;
		font-size: inherit;
		font-family: inherit;
		line-height: inherit;
	}
	button,
	input {
		overflow: visible;
	}
	button,
	select {
		text-transform: none;
	}
	button,
	html [type='button'],
	[type='reset'],
	[type='submit'] {
		-webkit-appearance: button;
	}
	button::-moz-focus-inner,
	[type='button']::-moz-focus-inner,
	[type='reset']::-moz-focus-inner,
	[type='submit']::-moz-focus-inner {
		padding: 0;
		border-style: none;
	}
	input[type='date'],
	input[type='time'],
	input[type='datetime-local'],
	input[type='month'] {
		-webkit-appearance: listbox;
	}
	textarea {
		overflow: auto;
		resize: vertical;
	}
	fieldset {
		min-width: 0;
		margin: 0;
		padding: 0;
		border: 0;
	}
	legend {
		display: block;
		width: 100%;
		max-width: 100%;
		margin-bottom: 0.5em;
		padding: 0;
		color: inherit;
		font-size: 1.5em;
		line-height: inherit;
		white-space: normal;
	}
	progress {
		vertical-align: baseline;
	}
	[type='number']::-webkit-inner-spin-button,
	[type='number']::-webkit-outer-spin-button {
		height: auto;
	}
	[type='search'] {
		outline-offset: -2px;
		-webkit-appearance: none;
	}
	[type='search']::-webkit-search-cancel-button,
	[type='search']::-webkit-search-decoration {
		-webkit-appearance: none;
	}
	::-webkit-file-upload-button {
		font: inherit;
		-webkit-appearance: button;
	}
	output {
		display: inline-block;
	}
	summary {
		display: list-item;
	}
	template {
		display: none;
	}
	[hidden] {
		display: none !important;
	}
	mark {
		padding: 0.2em;
		background-color: #feffe6;
	}
	::-moz-selection {
		color: #fff;
		background: #1890ff;
	}
	::selection {
		color: #fff;
		background: #1890ff;
	}
	.clearfix::before {
		display: table;
		content: '';
	}
	.clearfix::after {
		display: table;
		clear: both;
		content: '';
	}
	.anticon {
		display: inline-flex;
		align-items: center;
		color: inherit;
		font-style: normal;
		line-height: 0;
		text-align: center;
		text-transform: none;
		vertical-align: -0.125em;
		text-rendering: optimizelegibility;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}
	.anticon > * {
		line-height: 1;
	}
	.anticon svg {
		display: inline-block;
	}
	.anticon::before {
		display: none;
	}
	.anticon .anticon-icon {
		display: block;
	}
	.anticon > .anticon {
		line-height: 0;
		vertical-align: 0;
	}
	.anticon[tabindex] {
		cursor: pointer;
	}
	.anticon-spin,
	.anticon-spin::before {
		display: inline-block;
		animation: loadingCircle 1s infinite linear;
	}
	.ant-fade-enter,
	.ant-fade-appear {
		animation-duration: 0.2s;
		animation-fill-mode: both;
		animation-play-state: paused;
	}
	.ant-fade-leave {
		animation-duration: 0.2s;
		animation-fill-mode: both;
		animation-play-state: paused;
	}
	.ant-fade-enter.ant-fade-enter-active,
	.ant-fade-appear.ant-fade-appear-active {
		animation-name: antFadeIn;
		animation-play-state: running;
	}
	.ant-fade-leave.ant-fade-leave-active {
		animation-name: antFadeOut;
		animation-play-state: running;
		pointer-events: none;
	}
	.ant-fade-enter,
	.ant-fade-appear {
		opacity: 0;
		animation-timing-function: linear;
	}
	.ant-fade-leave {
		animation-timing-function: linear;
	}
	@keyframes antFadeIn {
		0% {
			opacity: 0;
		}
		100% {
			opacity: 1;
		}
	}
	@keyframes antFadeOut {
		0% {
			opacity: 1;
		}
		100% {
			opacity: 0;
		}
	}
	.ant-move-up-enter,
	.ant-move-up-appear {
		animation-duration: 0.2s;
		animation-fill-mode: both;
		animation-play-state: paused;
	}
	.ant-move-up-leave {
		animation-duration: 0.2s;
		animation-fill-mode: both;
		animation-play-state: paused;
	}
	.ant-move-up-enter.ant-move-up-enter-active,
	.ant-move-up-appear.ant-move-up-appear-active {
		animation-name: antMoveUpIn;
		animation-play-state: running;
	}
	.ant-move-up-leave.ant-move-up-leave-active {
		animation-name: antMoveUpOut;
		animation-play-state: running;
		pointer-events: none;
	}
	.ant-move-up-enter,
	.ant-move-up-appear {
		opacity: 0;
		animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
	}
	.ant-move-up-leave {
		animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
	}
	.ant-move-down-enter,
	.ant-move-down-appear {
		animation-duration: 0.2s;
		animation-fill-mode: both;
		animation-play-state: paused;
	}
	.ant-move-down-leave {
		animation-duration: 0.2s;
		animation-fill-mode: both;
		animation-play-state: paused;
	}
	.ant-move-down-enter.ant-move-down-enter-active,
	.ant-move-down-appear.ant-move-down-appear-active {
		animation-name: antMoveDownIn;
		animation-play-state: running;
	}
	.ant-move-down-leave.ant-move-down-leave-active {
		animation-name: antMoveDownOut;
		animation-play-state: running;
		pointer-events: none;
	}
	.ant-move-down-enter,
	.ant-move-down-appear {
		opacity: 0;
		animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
	}
	.ant-move-down-leave {
		animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
	}
	.ant-move-left-enter,
	.ant-move-left-appear {
		animation-duration: 0.2s;
		animation-fill-mode: both;
		animation-play-state: paused;
	}
	.ant-move-left-leave {
		animation-duration: 0.2s;
		animation-fill-mode: both;
		animation-play-state: paused;
	}
	.ant-move-left-enter.ant-move-left-enter-active,
	.ant-move-left-appear.ant-move-left-appear-active {
		animation-name: antMoveLeftIn;
		animation-play-state: running;
	}
	.ant-move-left-leave.ant-move-left-leave-active {
		animation-name: antMoveLeftOut;
		animation-play-state: running;
		pointer-events: none;
	}
	.ant-move-left-enter,
	.ant-move-left-appear {
		opacity: 0;
		animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
	}
	.ant-move-left-leave {
		animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
	}
	.ant-move-right-enter,
	.ant-move-right-appear {
		animation-duration: 0.2s;
		animation-fill-mode: both;
		animation-play-state: paused;
	}
	.ant-move-right-leave {
		animation-duration: 0.2s;
		animation-fill-mode: both;
		animation-play-state: paused;
	}
	.ant-move-right-enter.ant-move-right-enter-active,
	.ant-move-right-appear.ant-move-right-appear-active {
		animation-name: antMoveRightIn;
		animation-play-state: running;
	}
	.ant-move-right-leave.ant-move-right-leave-active {
		animation-name: antMoveRightOut;
		animation-play-state: running;
		pointer-events: none;
	}
	.ant-move-right-enter,
	.ant-move-right-appear {
		opacity: 0;
		animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
	}
	.ant-move-right-leave {
		animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
	}
	@keyframes antMoveDownIn {
		0% {
			transform: translateY(100%);
			transform-origin: 0 0;
			opacity: 0;
		}
		100% {
			transform: translateY(0%);
			transform-origin: 0 0;
			opacity: 1;
		}
	}
	@keyframes antMoveDownOut {
		0% {
			transform: translateY(0%);
			transform-origin: 0 0;
			opacity: 1;
		}
		100% {
			transform: translateY(100%);
			transform-origin: 0 0;
			opacity: 0;
		}
	}
	@keyframes antMoveLeftIn {
		0% {
			transform: translateX(-100%);
			transform-origin: 0 0;
			opacity: 0;
		}
		100% {
			transform: translateX(0%);
			transform-origin: 0 0;
			opacity: 1;
		}
	}
	@keyframes antMoveLeftOut {
		0% {
			transform: translateX(0%);
			transform-origin: 0 0;
			opacity: 1;
		}
		100% {
			transform: translateX(-100%);
			transform-origin: 0 0;
			opacity: 0;
		}
	}
	@keyframes antMoveRightIn {
		0% {
			transform: translateX(100%);
			transform-origin: 0 0;
			opacity: 0;
		}
		100% {
			transform: translateX(0%);
			transform-origin: 0 0;
			opacity: 1;
		}
	}
	@keyframes antMoveRightOut {
		0% {
			transform: translateX(0%);
			transform-origin: 0 0;
			opacity: 1;
		}
		100% {
			transform: translateX(100%);
			transform-origin: 0 0;
			opacity: 0;
		}
	}
	@keyframes antMoveUpIn {
		0% {
			transform: translateY(-100%);
			transform-origin: 0 0;
			opacity: 0;
		}
		100% {
			transform: translateY(0%);
			transform-origin: 0 0;
			opacity: 1;
		}
	}
	@keyframes antMoveUpOut {
		0% {
			transform: translateY(0%);
			transform-origin: 0 0;
			opacity: 1;
		}
		100% {
			transform: translateY(-100%);
			transform-origin: 0 0;
			opacity: 0;
		}
	}
	@keyframes loadingCircle {
		100% {
			transform: rotate(360deg);
		}
	}
	[ant-click-animating='true'],
	[ant-click-animating-without-extra-node='true'] {
		position: relative;
	}
	html {
		--antd-wave-shadow-color: #1890ff;
		--scroll-bar: 0;
	}
	[ant-click-animating-without-extra-node='true']::after,
	.ant-click-animating-node {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		display: block;
		border-radius: inherit;
		box-shadow: 0 0 0 0 #1890ff;
		box-shadow: 0 0 0 0 var(--antd-wave-shadow-color);
		opacity: 0.2;
		animation: fadeEffect 2s cubic-bezier(0.08, 0.82, 0.17, 1), waveEffect 0.4s cubic-bezier(0.08, 0.82, 0.17, 1);
		animation-fill-mode: forwards;
		content: '';
		pointer-events: none;
	}
	@keyframes waveEffect {
		100% {
			box-shadow: 0 0 0 #1890ff;
			box-shadow: 0 0 0 6px var(--antd-wave-shadow-color);
		}
	}
	@keyframes fadeEffect {
		100% {
			opacity: 0;
		}
	}
	.ant-slide-up-enter,
	.ant-slide-up-appear {
		animation-duration: 0.2s;
		animation-fill-mode: both;
		animation-play-state: paused;
	}
	.ant-slide-up-leave {
		animation-duration: 0.2s;
		animation-fill-mode: both;
		animation-play-state: paused;
	}
	.ant-slide-up-enter.ant-slide-up-enter-active,
	.ant-slide-up-appear.ant-slide-up-appear-active {
		animation-name: antSlideUpIn;
		animation-play-state: running;
	}
	.ant-slide-up-leave.ant-slide-up-leave-active {
		animation-name: antSlideUpOut;
		animation-play-state: running;
		pointer-events: none;
	}
	.ant-slide-up-enter,
	.ant-slide-up-appear {
		transform: scale(0);
		transform-origin: 0% 0%;
		opacity: 0;
		animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
	}
	.ant-slide-up-leave {
		animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
	}
	.ant-slide-down-enter,
	.ant-slide-down-appear {
		animation-duration: 0.2s;
		animation-fill-mode: both;
		animation-play-state: paused;
	}
	.ant-slide-down-leave {
		animation-duration: 0.2s;
		animation-fill-mode: both;
		animation-play-state: paused;
	}
	.ant-slide-down-enter.ant-slide-down-enter-active,
	.ant-slide-down-appear.ant-slide-down-appear-active {
		animation-name: antSlideDownIn;
		animation-play-state: running;
	}
	.ant-slide-down-leave.ant-slide-down-leave-active {
		animation-name: antSlideDownOut;
		animation-play-state: running;
		pointer-events: none;
	}
	.ant-slide-down-enter,
	.ant-slide-down-appear {
		transform: scale(0);
		transform-origin: 0% 0%;
		opacity: 0;
		animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
	}
	.ant-slide-down-leave {
		animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
	}
	.ant-slide-left-enter,
	.ant-slide-left-appear {
		animation-duration: 0.2s;
		animation-fill-mode: both;
		animation-play-state: paused;
	}
	.ant-slide-left-leave {
		animation-duration: 0.2s;
		animation-fill-mode: both;
		animation-play-state: paused;
	}
	.ant-slide-left-enter.ant-slide-left-enter-active,
	.ant-slide-left-appear.ant-slide-left-appear-active {
		animation-name: antSlideLeftIn;
		animation-play-state: running;
	}
	.ant-slide-left-leave.ant-slide-left-leave-active {
		animation-name: antSlideLeftOut;
		animation-play-state: running;
		pointer-events: none;
	}
	.ant-slide-left-enter,
	.ant-slide-left-appear {
		transform: scale(0);
		transform-origin: 0% 0%;
		opacity: 0;
		animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
	}
	.ant-slide-left-leave {
		animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
	}
	.ant-slide-right-enter,
	.ant-slide-right-appear {
		animation-duration: 0.2s;
		animation-fill-mode: both;
		animation-play-state: paused;
	}
	.ant-slide-right-leave {
		animation-duration: 0.2s;
		animation-fill-mode: both;
		animation-play-state: paused;
	}
	.ant-slide-right-enter.ant-slide-right-enter-active,
	.ant-slide-right-appear.ant-slide-right-appear-active {
		animation-name: antSlideRightIn;
		animation-play-state: running;
	}
	.ant-slide-right-leave.ant-slide-right-leave-active {
		animation-name: antSlideRightOut;
		animation-play-state: running;
		pointer-events: none;
	}
	.ant-slide-right-enter,
	.ant-slide-right-appear {
		transform: scale(0);
		transform-origin: 0% 0%;
		opacity: 0;
		animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
	}
	.ant-slide-right-leave {
		animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
	}

	@keyframes antSlideUpIn {
		0% {
			transform: scaleY(0.8);
			transform-origin: 0% 0%;
			opacity: 0;
		}
		100% {
			transform: scaleY(1);
			transform-origin: 0% 0%;
			opacity: 1;
		}
	}
	@keyframes antSlideUpOut {
		0% {
			transform: scaleY(1);
			transform-origin: 0% 0%;
			opacity: 1;
		}
		100% {
			transform: scaleY(0.8);
			transform-origin: 0% 0%;
			opacity: 0;
		}
	}
	@keyframes antSlideDownIn {
		0% {
			transform: scaleY(0.8);
			transform-origin: 100% 100%;
			opacity: 0;
		}
		100% {
			transform: scaleY(1);
			transform-origin: 100% 100%;
			opacity: 1;
		}
	}
	@keyframes antSlideDownOut {
		0% {
			transform: scaleY(1);
			transform-origin: 100% 100%;
			opacity: 1;
		}
		100% {
			transform: scaleY(0.8);
			transform-origin: 100% 100%;
			opacity: 0;
		}
	}
	@keyframes antSlideLeftIn {
		0% {
			transform: scaleX(0.8);
			transform-origin: 0% 0%;
			opacity: 0;
		}
		100% {
			transform: scaleX(1);
			transform-origin: 0% 0%;
			opacity: 1;
		}
	}
	@keyframes antSlideLeftOut {
		0% {
			transform: scaleX(1);
			transform-origin: 0% 0%;
			opacity: 1;
		}
		100% {
			transform: scaleX(0.8);
			transform-origin: 0% 0%;
			opacity: 0;
		}
	}
	@keyframes antSlideRightIn {
		0% {
			transform: scaleX(0.8);
			transform-origin: 100% 0%;
			opacity: 0;
		}
		100% {
			transform: scaleX(1);
			transform-origin: 100% 0%;
			opacity: 1;
		}
	}
	@keyframes antSlideRightOut {
		0% {
			transform: scaleX(1);
			transform-origin: 100% 0%;
			opacity: 1;
		}
		100% {
			transform: scaleX(0.8);
			transform-origin: 100% 0%;
			opacity: 0;
		}
	}
	.ant-zoom-enter,
	.ant-zoom-appear {
		animation-duration: 0.2s;
		animation-fill-mode: both;
		animation-play-state: paused;
	}
	.ant-zoom-leave {
		animation-duration: 0.2s;
		animation-fill-mode: both;
		animation-play-state: paused;
	}
	.ant-zoom-enter.ant-zoom-enter-active,
	.ant-zoom-appear.ant-zoom-appear-active {
		animation-name: antZoomIn;
		animation-play-state: running;
	}
	.ant-zoom-leave.ant-zoom-leave-active {
		animation-name: antZoomOut;
		animation-play-state: running;
		pointer-events: none;
	}
	.ant-zoom-enter,
	.ant-zoom-appear {
		transform: scale(0);
		opacity: 0;
		animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
	}
	.ant-zoom-enter-prepare,
	.ant-zoom-appear-prepare {
		transform: none;
	}
	.ant-zoom-leave {
		animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
	}
	.ant-zoom-big-enter,
	.ant-zoom-big-appear {
		animation-duration: 0.2s;
		animation-fill-mode: both;
		animation-play-state: paused;
	}
	.ant-zoom-big-leave {
		animation-duration: 0.2s;
		animation-fill-mode: both;
		animation-play-state: paused;
	}
	.ant-zoom-big-enter.ant-zoom-big-enter-active,
	.ant-zoom-big-appear.ant-zoom-big-appear-active {
		animation-name: antZoomBigIn;
		animation-play-state: running;
	}
	.ant-zoom-big-leave.ant-zoom-big-leave-active {
		animation-name: antZoomBigOut;
		animation-play-state: running;
		pointer-events: none;
	}
	.ant-zoom-big-enter,
	.ant-zoom-big-appear {
		transform: scale(0);
		opacity: 0;
		animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
	}
	.ant-zoom-big-enter-prepare,
	.ant-zoom-big-appear-prepare {
		transform: none;
	}
	.ant-zoom-big-leave {
		animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
	}
	.ant-zoom-big-fast-enter,
	.ant-zoom-big-fast-appear {
		animation-duration: 0.1s;
		animation-fill-mode: both;
		animation-play-state: paused;
	}
	.ant-zoom-big-fast-leave {
		animation-duration: 0.1s;
		animation-fill-mode: both;
		animation-play-state: paused;
	}
	.ant-zoom-big-fast-enter.ant-zoom-big-fast-enter-active,
	.ant-zoom-big-fast-appear.ant-zoom-big-fast-appear-active {
		animation-name: antZoomBigIn;
		animation-play-state: running;
	}
	.ant-zoom-big-fast-leave.ant-zoom-big-fast-leave-active {
		animation-name: antZoomBigOut;
		animation-play-state: running;
		pointer-events: none;
	}
	.ant-zoom-big-fast-enter,
	.ant-zoom-big-fast-appear {
		transform: scale(0);
		opacity: 0;
		animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
	}
	.ant-zoom-big-fast-enter-prepare,
	.ant-zoom-big-fast-appear-prepare {
		transform: none;
	}
	.ant-zoom-big-fast-leave {
		animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
	}
	.ant-zoom-up-enter,
	.ant-zoom-up-appear {
		animation-duration: 0.2s;
		animation-fill-mode: both;
		animation-play-state: paused;
	}
	.ant-zoom-up-leave {
		animation-duration: 0.2s;
		animation-fill-mode: both;
		animation-play-state: paused;
	}
	.ant-zoom-up-enter.ant-zoom-up-enter-active,
	.ant-zoom-up-appear.ant-zoom-up-appear-active {
		animation-name: antZoomUpIn;
		animation-play-state: running;
	}
	.ant-zoom-up-leave.ant-zoom-up-leave-active {
		animation-name: antZoomUpOut;
		animation-play-state: running;
		pointer-events: none;
	}
	.ant-zoom-up-enter,
	.ant-zoom-up-appear {
		transform: scale(0);
		opacity: 0;
		animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
	}
	.ant-zoom-up-enter-prepare,
	.ant-zoom-up-appear-prepare {
		transform: none;
	}
	.ant-zoom-up-leave {
		animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
	}
	.ant-zoom-down-enter,
	.ant-zoom-down-appear {
		animation-duration: 0.2s;
		animation-fill-mode: both;
		animation-play-state: paused;
	}
	.ant-zoom-down-leave {
		animation-duration: 0.2s;
		animation-fill-mode: both;
		animation-play-state: paused;
	}
	.ant-zoom-down-enter.ant-zoom-down-enter-active,
	.ant-zoom-down-appear.ant-zoom-down-appear-active {
		animation-name: antZoomDownIn;
		animation-play-state: running;
	}
	.ant-zoom-down-leave.ant-zoom-down-leave-active {
		animation-name: antZoomDownOut;
		animation-play-state: running;
		pointer-events: none;
	}
	.ant-zoom-down-enter,
	.ant-zoom-down-appear {
		transform: scale(0);
		opacity: 0;
		animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
	}
	.ant-zoom-down-enter-prepare,
	.ant-zoom-down-appear-prepare {
		transform: none;
	}
	.ant-zoom-down-leave {
		animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
	}
	.ant-zoom-left-enter,
	.ant-zoom-left-appear {
		animation-duration: 0.2s;
		animation-fill-mode: both;
		animation-play-state: paused;
	}
	.ant-zoom-left-leave {
		animation-duration: 0.2s;
		animation-fill-mode: both;
		animation-play-state: paused;
	}
	.ant-zoom-left-enter.ant-zoom-left-enter-active,
	.ant-zoom-left-appear.ant-zoom-left-appear-active {
		animation-name: antZoomLeftIn;
		animation-play-state: running;
	}
	.ant-zoom-left-leave.ant-zoom-left-leave-active {
		animation-name: antZoomLeftOut;
		animation-play-state: running;
		pointer-events: none;
	}
	.ant-zoom-left-enter,
	.ant-zoom-left-appear {
		transform: scale(0);
		opacity: 0;
		animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
	}
	.ant-zoom-left-enter-prepare,
	.ant-zoom-left-appear-prepare {
		transform: none;
	}
	.ant-zoom-left-leave {
		animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
	}
	.ant-zoom-right-enter,
	.ant-zoom-right-appear {
		animation-duration: 0.2s;
		animation-fill-mode: both;
		animation-play-state: paused;
	}
	.ant-zoom-right-leave {
		animation-duration: 0.2s;
		animation-fill-mode: both;
		animation-play-state: paused;
	}
	.ant-zoom-right-enter.ant-zoom-right-enter-active,
	.ant-zoom-right-appear.ant-zoom-right-appear-active {
		animation-name: antZoomRightIn;
		animation-play-state: running;
	}
	.ant-zoom-right-leave.ant-zoom-right-leave-active {
		animation-name: antZoomRightOut;
		animation-play-state: running;
		pointer-events: none;
	}
	.ant-zoom-right-enter,
	.ant-zoom-right-appear {
		transform: scale(0);
		opacity: 0;
		animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
	}
	.ant-zoom-right-enter-prepare,
	.ant-zoom-right-appear-prepare {
		transform: none;
	}
	.ant-zoom-right-leave {
		animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
	}
	@keyframes antZoomIn {
		0% {
			transform: scale(0.2);
			opacity: 0;
		}
		100% {
			transform: scale(1);
			opacity: 1;
		}
	}
	@keyframes antZoomOut {
		0% {
			transform: scale(1);
		}
		100% {
			transform: scale(0.2);
			opacity: 0;
		}
	}
	@keyframes antZoomBigIn {
		0% {
			transform: scale(0.8);
			opacity: 0;
		}
		100% {
			transform: scale(1);
			opacity: 1;
		}
	}
	@keyframes antZoomBigOut {
		0% {
			transform: scale(1);
		}
		100% {
			transform: scale(0.8);
			opacity: 0;
		}
	}
	@keyframes antZoomUpIn {
		0% {
			transform: scale(0.8);
			transform-origin: 50% 0%;
			opacity: 0;
		}
		100% {
			transform: scale(1);
			transform-origin: 50% 0%;
		}
	}
	@keyframes antZoomUpOut {
		0% {
			transform: scale(1);
			transform-origin: 50% 0%;
		}
		100% {
			transform: scale(0.8);
			transform-origin: 50% 0%;
			opacity: 0;
		}
	}
	@keyframes antZoomLeftIn {
		0% {
			transform: scale(0.8);
			transform-origin: 0% 50%;
			opacity: 0;
		}
		100% {
			transform: scale(1);
			transform-origin: 0% 50%;
		}
	}
	@keyframes antZoomLeftOut {
		0% {
			transform: scale(1);
			transform-origin: 0% 50%;
		}
		100% {
			transform: scale(0.8);
			transform-origin: 0% 50%;
			opacity: 0;
		}
	}
	@keyframes antZoomRightIn {
		0% {
			transform: scale(0.8);
			transform-origin: 100% 50%;
			opacity: 0;
		}
		100% {
			transform: scale(1);
			transform-origin: 100% 50%;
		}
	}
	@keyframes antZoomRightOut {
		0% {
			transform: scale(1);
			transform-origin: 100% 50%;
		}
		100% {
			transform: scale(0.8);
			transform-origin: 100% 50%;
			opacity: 0;
		}
	}
	@keyframes antZoomDownIn {
		0% {
			transform: scale(0.8);
			transform-origin: 50% 100%;
			opacity: 0;
		}
		100% {
			transform: scale(1);
			transform-origin: 50% 100%;
		}
	}
	@keyframes antZoomDownOut {
		0% {
			transform: scale(1);
			transform-origin: 50% 100%;
		}
		100% {
			transform: scale(0.8);
			transform-origin: 50% 100%;
			opacity: 0;
		}
	}
	.ant-motion-collapse-legacy {
		overflow: hidden;
	}
	.ant-motion-collapse-legacy-active {
		transition: height 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
	}
	.ant-motion-collapse {
		overflow: hidden;
		transition: height 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
	}
	.ant-select-single .ant-select-selector {
		display: block;
	}
	.ant-select-single .ant-select-selector .ant-select-selection-search {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
	}
	.ant-select-single .ant-select-selector .ant-select-selection-search-input {
		width: 100%;
	}
	.ant-select-single .ant-select-selector .ant-select-selection-item,
	.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
		padding: 0;
		transition: all 0.3s, visibility 0s;
	}
	.ant-select-single .ant-select-selector .ant-select-selection-item {
		position: relative;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}
	.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
		transition: none;
		pointer-events: none;
	}
	.ant-select-single .ant-select-selector::after,
	.ant-select-single .ant-select-selector .ant-select-selection-item::after,
	.ant-select-single .ant-select-selector .ant-select-selection-placeholder::after {
		display: inline-block;
		width: 0;
		visibility: hidden;
		content: '\a0';
	}
	.ant-select-single.ant-select-show-arrow .ant-select-selection-search {
		right: 24px;
	}
	.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
	.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
		/* text-align: right; */
		padding-right: calc(var(--sp3x) + 12px);
	}
	.ant-select-single.ant-select-open .ant-select-selection-item {
		font-size: var(--formSize);
		color: var(--white);
	}
	.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
		width: 100%;
		padding: 0;
	}
	.ant-select-single.ant-select-customize-input .ant-select-selector::after {
		display: none;
	}
	.ant-select-single.ant-select-customize-input .ant-select-selector .ant-select-selection-search {
		position: static;
		width: 100%;
	}
	.ant-select-single.ant-select-customize-input .ant-select-selector .ant-select-selection-placeholder {
		position: absolute;
		right: 0;
		left: 0;
		padding: 0 11px;
	}
	.ant-select-single.ant-select-customize-input .ant-select-selector .ant-select-selection-placeholder::after {
		display: none;
	}
	.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selection-search {
		right: 7px;
		left: 7px;
	}
	.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector {
		padding: 0 8px;
	}
	.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-search {
		right: 28px;
	}
	.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-item,
	.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-placeholder {
		padding-right: 20px;
	}
	.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
		padding: 0 12px;
	}
	.ant-select-selection-overflow {
		position: relative;
		display: flex;
		flex: auto;
		flex-wrap: wrap;
		max-width: 100%;
	}
	.ant-select-selection-overflow-item {
		flex: none;
		align-self: center;
		max-width: 100%;
	}
	.ant-select-multiple .ant-select-selector {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		padding: 0px 4px;
	}
	.ant-select-show-search.ant-select-multiple .ant-select-selector {
		cursor: text;
	}
	.ant-select-disabled.ant-select-multiple .ant-select-selector {
		background: #f5f5f5;
		cursor: not-allowed;
	}
	.ant-select-multiple .ant-select-selector::after {
		display: inline-block;
		width: 0;
		margin: 2px 0;
		visibility: hidden;
		content: '\a0';
	}
	.ant-select-multiple.ant-select-show-arrow .ant-select-selector,
	.ant-select-multiple.ant-select-allow-clear .ant-select-selector {
		padding-right: 24px;
	}
	.ant-select-multiple .ant-select-selection-item {
		position: relative;
		display: flex;
		flex: none;
		max-width: 100%;
		margin-top: 2px;
		margin-bottom: 2px;
		border: 1px solid #f0f0f0;
		border-radius: 2px;
		cursor: default;
		transition: font-size 0.3s, line-height 0.3s, height 0.3s;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		margin-inline-end: 4px;
		padding-inline-start: 8px;
		padding-inline-end: 4px;
	}
	.ant-select-disabled.ant-select-multiple .ant-select-selection-item {
		font-size: var(--formSize);
		color: var(--white);
		cursor: not-allowed;
	}
	.ant-select-multiple .ant-select-selection-item-content {
		display: inline-block;
		margin-right: 4px;
		overflow: hidden;
		white-space: pre;
		text-overflow: ellipsis;
	}
	.ant-select-multiple .ant-select-selection-item-remove {
		color: inherit;
		font-style: normal;
		line-height: 0;
		text-align: center;
		text-transform: none;
		vertical-align: -0.125em;
		text-rendering: optimizelegibility;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		display: inline-flex;
		align-items: center;
		color: rgba(0, 0, 0, 0.45);
		font-weight: bold;
		font-size: 12px;
		line-height: inherit;
		cursor: pointer;
	}
	.ant-select-multiple .ant-select-selection-item-remove > * {
		line-height: 1;
	}
	.ant-select-multiple .ant-select-selection-item-remove svg {
		display: inline-block;
	}
	.ant-select-multiple .ant-select-selection-item-remove::before {
		display: none;
	}
	.ant-select-multiple .ant-select-selection-item-remove .ant-select-multiple .ant-select-selection-item-remove-icon {
		display: block;
	}
	.ant-select-multiple .ant-select-selection-item-remove > .anticon {
		vertical-align: middle;
	}
	.ant-select-multiple .ant-select-selection-item-remove:hover {
		color: rgba(0, 0, 0, 0.75);
	}
	.ant-select-multiple .ant-select-selection-overflow-item + .ant-select-selection-overflow-item .ant-select-selection-search {
		margin-inline-start: 0;
	}
	.ant-select-multiple .ant-select-selection-search {
		position: relative;
		max-width: 100%;
		margin-inline-start: 7px;
	}
	.ant-select-multiple .ant-select-selection-search-input,
	.ant-select-multiple .ant-select-selection-search-mirror {
		height: 24px;
		font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
		line-height: 24px;
		transition: all 0.3s;
	}
	.ant-select-multiple .ant-select-selection-search-input {
		width: 100%;
		min-width: 4.1px;
	}
	.ant-select-multiple .ant-select-selection-search-mirror {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 999;
		white-space: pre;
		visibility: hidden;
	}
	.ant-select-multiple .ant-select-selection-placeholder {
		position: absolute;
		top: 50%;
		right: 12px;
		left: 12px;
		transform: translateY(-50%);
		transition: all 0.3s;
	}
	.ant-select-multiple.ant-select-sm .ant-select-selection-placeholder {
		left: 8px;
	}
	.ant-select-multiple.ant-select-sm .ant-select-selection-search {
		margin-inline-start: 2px;
	}
	.ant-select-disabled .ant-select-selection-item-remove {
		display: none;
	}
	.ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) .ant-select-selector {
		background-color: rgba(99, 78, 195, 0.15);
		border-color: #f77566 !important;
	}
	.ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer).ant-select-open .ant-select-selector,
	.ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer).ant-select-focused .ant-select-selector {
		border-color: #f77566;
		outline: 0;
	}
	.ant-select-status-warning.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) .ant-select-selector {
		background-color: rgba(99, 78, 195, 0.15);
		border-color: #faad14 !important;
	}
	.ant-select-status-warning.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer).ant-select-open .ant-select-selector,
	.ant-select-status-warning.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer).ant-select-focused .ant-select-selector {
		border-color: #ffc53d;
		outline: 0;
	}
	.ant-select-status-error.ant-select-has-feedback .ant-select-clear,
	.ant-select-status-warning.ant-select-has-feedback .ant-select-clear,
	.ant-select-status-success.ant-select-has-feedback .ant-select-clear,
	.ant-select-status-validating.ant-select-has-feedback .ant-select-clear {
		right: 32px;
	}
	.ant-select-status-error.ant-select-has-feedback .ant-select-selection-selected-value,
	.ant-select-status-warning.ant-select-has-feedback .ant-select-selection-selected-value,
	.ant-select-status-success.ant-select-has-feedback .ant-select-selection-selected-value,
	.ant-select-status-validating.ant-select-has-feedback .ant-select-selection-selected-value {
		padding-right: 40px;
	}
	.ant-select {
		font-variant: tabular-nums;
		font-feature-settings: 'tnum';
		list-style: none;
		position: relative;
		display: inline-block;
		cursor: pointer;
	}
	.ant-select:not(.ant-select-customize-input) .ant-select-selector {
		position: relative;
		background-color: transparent;
		border: unset;
		border-radius: 0;
		transition: unset;
	}
	.ant-select:not(.ant-select-customize-input) .ant-select-selector input {
		cursor: pointer;
	}
	.ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector {
		cursor: text;
	}
	.ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector input {
		cursor: auto;
	}
	.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
		border-color: unset;
		box-shadow: unset;
		border-right-width: unset;
		outline: 0;
	}
	.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
		color: rgba(0, 0, 0, 0.25);
		background-color: rgba(99, 78, 195, 0.15);
		cursor: not-allowed;
	}
	.ant-select-multiple.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
		background-color: rgba(99, 78, 195, 0.15);
	}
	.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector input {
		cursor: not-allowed;
	}
	.ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
		background: transparent;
		border: none;
		outline: none;
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
	}
	.ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input::-webkit-search-cancel-button {
		display: none;
		-webkit-appearance: none;
	}
	.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
		border-color: unset;
		border-right-width: unset;
	}
	.ant-select-selection-item {
		flex: 1;
		overflow: hidden;
		font-weight: normal;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
	@media all and (-ms-high-contrast: none) {
		.ant-select-selection-item *::-ms-backdrop,
		.ant-select-selection-item {
			flex: auto;
		}
	}
	.ant-select-selection-placeholder {
		flex: 1;
		overflow: hidden;
		color: var(--black);
		white-space: nowrap;
		text-overflow: ellipsis;
		pointer-events: none;
	}
	@media all and (-ms-high-contrast: none) {
		.ant-select-selection-placeholder *::-ms-backdrop,
		.ant-select-selection-placeholder {
			flex: auto;
		}
	}
	.ant-select-arrow {
		display: inline-flex;
		color: inherit;
		font-style: normal;
		text-transform: none;
		vertical-align: -0.125em;
		text-rendering: optimizelegibility;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: 21px;
		display: flex;
		align-items: center;
		color: var(--black);
		font-size: 12px;
		line-height: 1;
		text-align: center;
		pointer-events: none;

		i {
			transition: 0.3s ease-out;
		}
	}
	.ant-select-arrow > * {
		line-height: 1;
	}
	.ant-select-arrow svg {
		display: inline-block;
	}
	.ant-select-arrow::before {
		display: none;
	}
	.ant-select-arrow .ant-select-arrow-icon {
		display: block;
	}
	.ant-select-arrow .anticon {
		vertical-align: top;
		transition: transform 0.3s;
	}
	.ant-select-arrow .anticon > svg {
		vertical-align: top;
	}
	.ant-select-arrow .anticon:not(.ant-select-suffix) {
		pointer-events: auto;
	}
	.ant-select-disabled .ant-select-arrow {
		cursor: not-allowed;
	}
	.ant-select-arrow > *:not(:last-child) {
		margin-inline-start: 8px;
	}
	.ant-select-clear {
		position: absolute;
		top: 50%;
		right: 11px;
		z-index: 1;
		display: inline-block;
		width: 12px;
		height: 12px;
		margin-top: -6px;
		color: rgba(0, 0, 0, 0.25);
		font-size: 12px;
		font-style: normal;
		line-height: 1;
		text-align: center;
		text-transform: none;
		background-color: rgba(99, 78, 195, 0.15);
		cursor: pointer;
		opacity: 0;
		transition: color 0.3s ease, opacity 0.15s ease;
		text-rendering: auto;
	}
	.ant-select-clear::before {
		display: block;
	}
	.ant-select-clear:hover {
		color: rgba(0, 0, 0, 0.45);
	}
	.ant-select:hover .ant-select-clear {
		opacity: 1;
	}
	.ant-select-dropdown {
		list-style: none;
		position: absolute;
		top: 24px !important;
		left: -9999px;
		z-index: 1050;
		padding: 0;
		overflow: hidden;
		font-variant: initial;
		font-feature-settings: 'tnum';
		background-color: rgba(99, 78, 195, 0.15);
		border-radius: 0;
		outline: none;
		box-shadow: unset;
	}
	.ant-select-dropdown.ant-slide-up-enter.ant-slide-up-enter-active.ant-select-dropdown-placement-bottomLeft,
	.ant-select-dropdown.ant-slide-up-appear.ant-slide-up-appear-active.ant-select-dropdown-placement-bottomLeft {
		animation-name: antSlideUpIn;
	}
	.ant-select-dropdown.ant-slide-up-enter.ant-slide-up-enter-active.ant-select-dropdown-placement-topLeft,
	.ant-select-dropdown.ant-slide-up-appear.ant-slide-up-appear-active.ant-select-dropdown-placement-topLeft {
		animation-name: antSlideDownIn;
	}
	.ant-select-dropdown.ant-slide-up-leave.ant-slide-up-leave-active.ant-select-dropdown-placement-bottomLeft {
		animation-name: antSlideUpOut;
	}
	.ant-select-dropdown.ant-slide-up-leave.ant-slide-up-leave-active.ant-select-dropdown-placement-topLeft {
		animation-name: antSlideDownOut;
	}
	.ant-select-dropdown-hidden {
		display: none;
	}
	.ant-select-dropdown-empty {
		color: rgba(0, 0, 0, 0.25);
	}
	.ant-select-item-empty {
		position: relative;
		display: block;
		min-height: 32px;
		padding: 5px 12px;
		color: var(--black);
		font-weight: normal;
		font-size: 14px;
		line-height: 22px;
		color: rgba(0, 0, 0, 0.25);
	}
	.ant-select-item {
		position: relative;
		display: block;
		padding: 0;
		color: var(--black);
		font-size: var(--h3);
		cursor: pointer;
		transition: background 0.3s ease;

		&:not(:first-child) {
			/* padding-top: var(--sp1x); */
		}
	}
	.ant-select-item-group {
		color: rgba(0, 0, 0, 0.45);
		font-size: 12px;
		cursor: default;
	}
	.ant-select-item-option {
		display: flex;
	}
	.ant-select-item-option-content {
		flex: auto;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
	.ant-select-item-option-state {
		flex: none;
	}
	.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
		background-color: rgba(99, 78, 195, 0.15);
		color: var(--green);
	}
	.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
		color: var(--green);
		font-family: var(--golos_text);
		font-weight: 600 !important;
	}
	.ant-select-item-option-selected:not(.ant-select-item-option-disabled) .ant-select-item-option-state {
		color: #1890ff;
	}
	.ant-select-item-option-disabled {
		color: rgba(0, 0, 0, 0.25);
		cursor: not-allowed;
	}
	.ant-select-item-option-disabled.ant-select-item-option-selected {
		background-color: #f5f5f5;
	}
	.ant-select-item-option-grouped {
		padding-left: 24px;
	}
	.ant-select-lg {
		font-size: 16px;
	}
	.ant-select-borderless .ant-select-selector {
		background-color: transparent !important;
		border-color: transparent !important;
		box-shadow: none !important;
	}
	.ant-select.ant-select-in-form-item {
		width: 100%;
	}
	.ant-select-compact-item:not(.ant-select-compact-last-item) {
		margin-right: -1px;
	}
	.ant-select-compact-item:not(.ant-select-compact-last-item).ant-select-compact-item-rtl {
		margin-right: 0;
		margin-left: -1px;
	}
	.ant-select-compact-item:hover > *,
	.ant-select-compact-item:focus > *,
	.ant-select-compact-item:active > * {
		z-index: 2;
	}
	.ant-select-compact-item.ant-select-focused > * {
		z-index: 2;
	}
	.ant-select-compact-item[disabled] > * {
		z-index: 0;
	}
	.ant-select-compact-item:not(.ant-select-compact-first-item):not(.ant-select-compact-last-item).ant-select > .ant-select-selector {
		border-radius: 0;
	}
	.ant-select-compact-item.ant-select-compact-first-item.ant-select:not(.ant-select-compact-last-item):not(.ant-select-compact-item-rtl) > .ant-select-selector {
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
	}
	.ant-select-compact-item.ant-select-compact-last-item.ant-select:not(.ant-select-compact-first-item):not(.ant-select-compact-item-rtl) > .ant-select-selector {
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
	}
	.ant-select-compact-item.ant-select.ant-select-compact-first-item.ant-select-compact-item-rtl:not(.ant-select-compact-last-item) > .ant-select-selector {
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
	}
	.ant-select-compact-item.ant-select.ant-select-compact-last-item.ant-select-compact-item-rtl:not(.ant-select-compact-first-item) > .ant-select-selector {
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
	}
	.ant-select-rtl {
		direction: rtl;
	}
	.ant-select-rtl .ant-select-arrow {
		right: initial;
		left: 11px;
	}
	.ant-select-rtl .ant-select-clear {
		right: initial;
		left: 11px;
	}
	.ant-select-dropdown-rtl {
		direction: rtl;
	}
	.ant-select-dropdown-rtl .ant-select-item-option-grouped {
		padding-right: 24px;
		padding-left: 12px;
	}
	.ant-select-rtl.ant-select-multiple.ant-select-show-arrow .ant-select-selector,
	.ant-select-rtl.ant-select-multiple.ant-select-allow-clear .ant-select-selector {
		padding-right: 4px;
		padding-left: 24px;
	}
	.ant-select-rtl.ant-select-multiple .ant-select-selection-item {
		text-align: right;
	}
	.ant-select-rtl.ant-select-multiple .ant-select-selection-item-content {
		margin-right: 0;
		margin-left: 4px;
		text-align: right;
	}
	.ant-select-rtl.ant-select-multiple .ant-select-selection-search-mirror {
		right: 0;
		left: auto;
	}
	.ant-select-rtl.ant-select-multiple .ant-select-selection-placeholder {
		right: 12px;
		left: auto;
	}
	.ant-select-rtl.ant-select-multiple.ant-select-sm .ant-select-selection-placeholder {
		right: 8px;
	}
	.ant-select-rtl.ant-select-single .ant-select-selector .ant-select-selection-item,
	.ant-select-rtl.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
		right: 0;
		left: 8px;
		text-align: right;
	}
	.ant-select-rtl.ant-select-single.ant-select-show-arrow .ant-select-selection-search {
		right: 12px;
		left: 0;
		margin-top: 45px;
		color: black;
		background-color: rgba(99, 78, 195, 0.15);
		z-index: 1111;
	}
	.ant-select-rtl.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
	.ant-select-rtl.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
		padding-right: 0;
		padding-left: 0;
	}
	.ant-select-rtl.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-search {
		right: 6px;
	}
	.ant-select-rtl.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-item,
	.ant-select-rtl.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-placeholder {
		padding-right: 0;
		padding-left: 20px;
	}
	.ant-empty {
		margin: 0 8px;
		font-size: 16px;
		line-height: 1;
		text-align: center;
	}
	.ant-empty-image {
		height: 96px;
		margin-bottom: 8px;
	}
	.ant-empty-image img {
		height: 100%;
	}
	.ant-empty-image svg {
		height: 100%;
		margin: auto;
	}
	.ant-empty-footer {
		margin-top: 16px;
	}
	.ant-empty-normal {
		margin: 32px 0;
		color: rgba(0, 0, 0, 0.25);
	}
	.ant-empty-normal .ant-empty-image {
		height: 40px;
	}
	.ant-empty-small {
		margin: 8px 0;
		color: rgba(0, 0, 0, 0.25);
	}
	.ant-empty-small .ant-empty-image {
		height: 35px;
	}
	.ant-empty-img-default-ellipse {
		fill: #f5f5f5;
		fill-opacity: 0.8;
	}
	.ant-empty-img-default-path-1 {
		fill: #aeb8c2;
	}
	.ant-empty-img-default-path-2 {
		fill: url('#linearGradient-1');
	}
	.ant-empty-img-default-path-3 {
		fill: #f5f5f7;
	}
	.ant-empty-img-default-path-4 {
		fill: #dce0e6;
	}
	.ant-empty-img-default-path-5 {
		fill: #dce0e6;
	}
	.ant-empty-img-default-g {
		fill: #fff;
	}
	.ant-empty-img-simple-ellipse {
		fill: #f5f5f5;
	}
	.ant-empty-img-simple-g {
		stroke: #d9d9d9;
	}
	.ant-empty-img-simple-path {
		fill: #fafafa;
	}
	.ant-empty-rtl {
		direction: rtl;
	}
	/* //! Custom Style */
	.ant-form-item-feedback-icon {
		display: none;
	}

	.ant-select-selection-item {
		color: var(--black);
		font-size: var(--formSize);
		font-family: var(--golos_text);
		font-weight: 600 !important;
	}

	.ant-select {
		&.ant-select-open {
			.select-arrow {
				transform: rotate(180deg);
			}
			.ant-select-selector {
				transition: all var(--trTime) ease-out;
				border-bottom-left-radius: 0px !important;
				border-bottom-right-radius: 0px !important;
			}
		}
	}

	.ant-select-dropdown {
		border-radius: var(--sp1x);
		box-shadow: 0px 2px 6px #8d8d8d29;
		background-color: rgba(99, 78, 195, 0.15);
		border: 1px solid var(--white);
		border-top-left-radius: 0px;
		border-top-right-radius: 0px;
		color: var(--white);
		margin-top: var(--antSelectDropdownMTop);
		left: 0 !important;
		z-index: 111;
	}

	.ant-select-selector {
		padding: var(--inputPadTB) var(--inputPadLR) !important;
		border-radius: var(--sp1x) !important;
		background-color: rgba(99, 78, 195, 0.15) !important;
		-webkit-backdrop-filter: blur(44px);
		backdrop-filter: blur(44px);
		width: 100%;
		font-size: var(--formSize) !important;
		color: var(--white);
		transition: unset;
		border: 1px solid var(--white) !important;
		font-family: var(--golos_text) !important;
		font-weight: 400;

		&::placeholder {
			font-size: var(--formSize);
			color: var(--white);
		}

		@media (hover: hover) {
			&:hover {
				background-color: rgba(34, 33, 105, 0.15);
			}
		}

		&:focus {
			border: 1px solid var(--focused);
		}

		&:active {
			color: rgba(255, 255, 255, 1);

			&::placeholder {
				color: rgba(255, 255, 255, 1);
			}
		}
	}
	.ant-select-item > div {
		padding: var(--inputPadTB) var(--inputPadLR);
		font-family: var(--golos_text);
		font-size: var(--formSize);
		transition: all var(--trTime) ease-out;
	}
	.ant-select-item {
		@media (hover: hover) {
			> div:hover {
				background-color: rgb(26 52 127);
			}
		}
	}
	.ant-select-arrow {
		font-size: var(--h4);
		color: var(--white);
	}
	.rc-virtual-list {
		background-color: #223887;
	}
	/* @media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeSMin}) {
		--antSelectDropdownBorderRadius: var(--sp2x);
		--antSelectDropdownPad: var(--sp2x);
		--antSelectDropdownMTop: var(--sp2x);
	} */

	/* //! 1920 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeL}) {
		--inputPadTB: var(--sp3x);
		--inputPadLR: var(--sp3x);
		--antSelectDropdownMTop: 50px;
	}

	/* //! 1536 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeM}) {
		--inputPadTB: var(--sp2-5x);
		--inputPadLR: var(--sp2-5x);
		--antSelectDropdownMTop: 43px;
	}

	/* //! 1366 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeS}) {
		--inputPadTB: var(--sp2-5x);
		--inputPadLR: var(--sp2-5x);
		--antSelectDropdownMTop: 36px;
	}

	/* //! 1280 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.tabletSize}) {
		--inputPadTB: var(--sp2-5x);
		--inputPadLR: var(--sp2-5x);
		--antSelectDropdownMTop: 36px;
	}

	/* //! 768 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.tabletSizeS}) {
		--inputPadTB: var(--sp2x);
		--inputPadLR: var(--sp2x);
		--antSelectDropdownMTop: 28px;
	}

	/* //! Mobile */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeSMin}) {
		--inputPadTB: var(--sp1-5x);
		--inputPadLR: var(--sp1-5x);
		--antSelectDropdownBorderRadius: var(--sp2x);
		--antSelectDropdownPad: var(--sp2x);
		/* --antSelectDropdownMTop: var(--sp2x); */
		--antSelectDropdownMTop: 20px;
	}
`;

export default FormSelectStyle;
