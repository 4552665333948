import styled from 'styled-components';

const ContactUsSectionStyle = styled.section`
	/* --largeMTop: var(--sp9x); */
	--largeMTop: var(--sp7x);

	--marginTop: var(--sp5x);
	--itemsPadding: var(--sp3x);

	.large-mT {
		margin-top: var(--largeMTop);
	}

	.radioLabel {
		div {
			.ant-form-item-label {
				opacity: 0.7;
			}
		}
	}

	.link {
		text-decoration: underline;
	}

	span.ant-radio + *,
	.ant-checkbox + span {
		padding-left: var(--itemsPadding);
	}

	.ant-select-selector {
		display: flex !important;
	}

	.ant-select-selection-search {
		display: none;
	}

	.ant-radio-group {
		margin-top: var(--marginTop);
	}

	/* //! 1920 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeL}) {
		/* --largeMTop: var(--sp7x); */
		--largeMTop: var(--sp5x);
		--marginTop: var(--sp4x);
		--itemsPadding: var(--sp2x);
	}

	/* //! 1536 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeM}) {
		/* --largeMTop: var(--sp7x); */
		--marginTop: var(--sp4x);
		--itemsPadding: var(--sp2x);
		--largeMTop: var(--sp5x);
	}

	/* //! 1366 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeS}) {
		/* --largeMTop: var(--sp7x); */
		--marginTop: var(--sp4x);
		--itemsPadding: var(--sp2x);
		--largeMTop: var(--sp5x);
	}

	/* //! 1280 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.tabletSize}) {
		/* --largeMTop: var(--sp7x); */
		--marginTop: var(--sp3x);
		--itemsPadding: var(--sp2x);
		--largeMTop: var(--sp5x);
	}

	/* //! 768 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.tabletSizeS}) {
		/* --largeMTop: var(--sp5x); */
		--marginTop: var(--sp3x);
		--itemsPadding: var(--sp2x);
		--largeMTop: var(--sp3x);
	}

	/* //! Mobile */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeSMin}) {
		/* --largeMTop: var(--sp3x); */
		--marginTop: var(--sp2x);
		--itemsPadding: var(--sp1-5x);
		--largeMTop: var(--sp2-5x);

		.last-name {
			padding-top: var(--largeMTop);
		}
	}
`;
export default ContactUsSectionStyle;
