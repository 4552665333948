import { useEffect } from 'react';
import gsap from 'gsap';
import { SplitText } from 'gsap/all';
import { withUIContext } from '@/context';

const ScrollActive = ({ lineAnim, opacity, animY, vertical, refsContainer, bottomLine, delay, stagger, duration, children, isMobileDelayed = 0.01, isMobileDuration = 0.8, isMobileStagger, isMobileBottomLine }) => {
	useEffect(() => {
		const isMobile = window.innerWidth < 768;
		//! Control variables all type animation
		const config = {
			duration: 0.8,
			stagger: 0.1,
			delay: 0.1,
		};

		//! PLUGIN GSAP
		gsap.registerPlugin(SplitText);

		//! ALL Refs
		const allRefs = refsContainer?.map((item) => item.current);

		//! Function Line Height to Percent
		function lineNumber(item) {
			let lineHeight = parseFloat(window.getComputedStyle(item).lineHeight);
			return (item.offsetHeight / lineHeight) * 100;
		}

		//! Animation Line

		if (lineAnim) {
			allRefs.map((item) => {
				const tl = gsap.timeline({
					scrollTrigger: {
						trigger: item,
						start: 'top 100%',
						end: 'top top',
						// markers: true,
					},
				});

				const mySplitText = new SplitText(item, { type: 'lines,lines' });
				const chars = mySplitText.lines;

				return tl.to(chars, {
					duration: isMobile ? isMobileDuration : duration || config.duration,
					ease: 'power2.out',
					yPercent: `-${lineNumber(item)}`,
					stagger: stagger || config.stagger,
					delay: isMobile ? isMobileDelayed : delay || 0.1,
				});
			});
		}

		//! Animation Opacity
		if (opacity) {
			allRefs.map((item, i) => {
				return gsap.to(item, {
					scrollTrigger: {
						trigger: item,
						start: bottomLine || `bottom 100%`,
						end: 'bottom top',
						// markers: true,
					},
					opacity: 1,
					ease: 'power1.in',
					delay: isMobile ? isMobileDelayed : delay || config.delay,
					duration: isMobile ? isMobileDuration : duration || config.duration,
				});
			});
		}

		//! Animation up Y control className
		if (animY) {
			allRefs.map((item, i) => {
				if (vertical) {
					return gsap.to(item, {
						scrollTrigger: {
							trigger: vertical.current,
							start: isMobile ? isMobileBottomLine : bottomLine || '30% 100%',
							end: '30% top',
							// markers: true,
						},
						opacity: 1,
						delay: i * 0.1,
						ease: 'power2.out',
						y: 0,
						duration: isMobile ? isMobileDuration : duration || config.duration,
					});
				} else {
					return gsap.to(item, {
						scrollTrigger: {
							trigger: item,
							start: isMobile ? isMobileBottomLine : bottomLine || 'top 100%',
							end: 'top top',
							// markers: true,
						},

						opacity: 1,
						delay: isMobile ? isMobileDelayed : delay || i * 0.1,
						ease: 'power2.out',
						y: 0,
						duration: isMobile ? isMobileDuration : duration || config.duration,
					});
				}
			});
		}
	}, [lineAnim, opacity]);

	return children;
};

export default ScrollActive;
